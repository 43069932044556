import { useState } from "react";

export default function TTC2Tabs({ options, handleClick }) {
  const [active, setActive] = useState("translate-x-0");
  const [selection, setSelection] = useState(0);

  const selected = "text-trpr-green-selected";
  const unselected = "text-trpr-green-unselected";

  const handleToggle = (selection, options) => {
    setSelection(selection);
    if (selection === 0) {
      setActive("translate-x-0");
      handleClick(options.value);
    } else {
      setActive("translate-x-full");
      handleClick(options.value);
    }
  };

  return (
    <div className="flex rounded-lg relative items-center bg-trpr-dark-green w-full border-trpr-green-highlight border-2">
      <div className="mx-1 relative w-full flex items-center">
        <div
          className={`absolute rounded-md bg-white shadow h-4/5 w-1/2 ${active} transition-translate duration-300`}
        ></div>

        <div
          className="flex box-border z-10 rounded-lg p-0.5 w-full"
          role="tablist"
          aria-orientation="horizontal"
        >
          <button
            className="w-1/2 flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold"
            id="headlessui-tabs-tab-112"
            role="tab"
            type="button"
            aria-selected={selection ? true : false}
            tabIndex="-1"
            data-headlessui-state={selection === 1 ? "selected" : "unselected"}
            aria-controls="headlessui-tabs-panel-114"
            onClick={() => handleToggle(0, options[0])}
          >
            <span
              className={`w-full transition-color duration-500 ${
                selection === 0 ? selected : unselected
              }`}
            >
              {options[0].label}
            </span>
          </button>
          <button
            className="w-1/2 flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold"
            id="headlessui-tabs-tab-113"
            role="tab"
            type="button"
            aria-selected={selection ? true : false}
            tabIndex="0"
            data-headlessui-state={selection === 1 ? "selected" : "unselected"}
            aria-controls="headlessui-tabs-panel-115"
            onClick={() => handleToggle(1, options[1])}
          >
            <span
              className={`w-full transition-color duration-500 ${
                selection === 1 ? selected : unselected
              }`}
            >
              {options[1].label}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
