import CopyButton from "./CopyButton";
import { useRef, useState } from "react";

export default function CodeBlock({ btnLabel, children }) {
  const [btn, setBtn] = useState(btnLabel);

  return (
    <>
      <code className="code-block w-full" id="copy-launcher-code">
        {children}
      </code>
      <div className="flex content-start w-full">
        <button
          onClick={() => {
            navigator.clipboard.writeText(children).then(setBtn("Copied!"));
          }}
          className="primary-button flex flex-row items-center"
        >
          <CopyButton /> {btn}
        </button>
      </div>
    </>
  );
}
