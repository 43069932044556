import logo from "./logo.svg";
import "./App.css";
import {
  createElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Select from "react-tailwindcss-select";
import ttcLogo from "./img/ttc-logo-full.svg";
import TTC2Tabs from "./components/TTC2Tabs";
import CodeBlock from "./components/CodeBlock";

function App() {
  const itineraryLauncherOptions = [
    { value: "BUTTON", label: "Simple Button" },
    // { value: "MAP_PATH", label: "Route" },
    { value: "MAP", label: "Carousel" },
    { value: "", label: "Default" },
  ];

  const atlasLauncherOptions = [
    { value: "BUTTON", label: "Simple Button" },
    { value: "", label: "Default" },
  ];

  const ref = useRef();

  const [trprID, setTrprID] = useState(10913);
  const [btnLabel, setBtnLabel] = useState("Book Now!");
  const [variant, setVariant] = useState("MAP");
  const [selectedOption, setSelectedOption] = useState(
    itineraryLauncherOptions[1]
  );
  const [lang, setLang] = useState("en");
  const [options, setOptions] = useState(itineraryLauncherOptions);
  const [guideType, setGuideType] = useState("ITINERARY");

  const atlasComponentBase = createElement(`<trpr-launcher
              type="ITINERARY"
              id="10913"
              lang={lang}
              variant={variant}
              key={key}
              btn-label={btnLabel}
              ref={wcRef}
              bkr-id={trprID}
            />`);

  useEffect(() => {
    if (guideType === "ITINERARY") {
      setOptions(itineraryLauncherOptions);
      setTrprID(10913);
    } else {
      setOptions(atlasLauncherOptions);
      setTrprID(15625);
    }
  }, [guideType]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setVariant(option.value);
  };

  const wcRef = useRef(null);

  return (
    <>
      {/*<Menu>*/}
      {/*  <ul className="flex flex-1 flex-col left-0 items-center justify-between min-h-[250px]">*/}
      {/*    <li className="border-b border-gray-400 my-8 uppercase">*/}
      {/*      <button onClick={() => handleClick()}>About</button>*/}
      {/*    </li>*/}
      {/*    <li className="border-b border-gray-400 my-8 uppercase">*/}
      {/*      <a href="/portfolio">Portfolio</a>*/}
      {/*    </li>*/}
      {/*    <li className="border-b border-gray-400 my-8 uppercase">*/}
      {/*      <a href="/contact">Contact</a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</Menu>*/}
      <div className="CONTAINER-WRAPPER md:flex h-screen">
        <div className="INSTRUCTIONS flex flex-1 left-0 overflow-auto flex-col gap-6 p-6 bg-trpr-green">
          <img className="max-w-[200px] my-3 block m-auto" src={ttcLogo} />
          <h1 className="lexend title">Tripper :: Launcher Playground</h1>
          <hr className="page-divider" />
          <h2 className="lexend header">Embedding Instructions</h2>
          <div className="playground-section">
            <div className="inter body-text">
              Make sure to include the following scripts on your page, ideally
              before the closing <code>&lt;/body&gt;</code> tag.
            </div>

            <CodeBlock btnLabel="Copy Scripts">
              {`<script type="module" src="https://rto3-launcher.tripper.app/build/ttc-tripper-launcher.esm.js" data-stencil-namespace="ttc-tripper-launcher"></script>
  
                <script nomodule src="https://rto3-launcher.tripper.app/build/ttc-tripper-launcher.esm.js" data-stencil-namespace="ttc-tripper-launcher"></script>
              `}
            </CodeBlock>

            <hr className="page-divider mt-5" />
            <h2 className="lexend header">
              Generate a Launcher (<strong>Beta</strong>)
            </h2>
            <div className="inter body-text">
              <strong>Tripper</strong> comes pre-built with different launcher
              styles depending on the kind of guide you are creating. Build your
              launcher by selecting options below.
              <br />
              <br />
              NOTE: the live launcher preview feature in this playground is
              still being developed. But the code generated, based on the
              options below, is correct and can be used in production.
            </div>
            <div className="TOGGLES w-1/2 flex flex-col gap-5">
              <TTC2Tabs
                options={[
                  { label: "Itinerary", value: "ITINERARY" },
                  { label: "Atlas", value: "ATLAS" },
                ]}
                handleClick={setGuideType}
              />
              <TTC2Tabs
                options={[
                  { label: "English", value: "en" },
                  { label: "French", value: "fr" },
                ]}
                handleClick={setLang}
              />
              <label>
                Select Launcher Style
                <Select
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                />
              </label>
              {/*<form>*/}
              {/*  <label>*/}
              {/*    Enter Launcher Label*/}
              {/*    <input type="text" name="name" />*/}
              {/*  </label>*/}
              {/*</form>*/}
            </div>
            <hr className="page-divider mt-5" />
            <h2 className="lexend header">Generate an HTML Wrapper Launcher</h2>
            <div className="inter body-text px-3">
              Tripper launchers can be further customized by using the launcher
              code to wrap your own html elements, even just a{" "}
              <ttc-tripper-launcher
                type="ITINERARY"
                trpr-id="14765"
                lang="en"
                style={{ display: "inline-block" }}
              >
                <a href="#!" className="text-white underline">
                  simple text link
                </a>
              </ttc-tripper-launcher>
              .
            </div>

            <CodeBlock btnLabel="Copy launcher wrapper code">
              {`<ttc-tripper-launcher type="${guideType}" trpr-id="${trprID}" lang="${lang}" /><a href="#!" className="text-white underline">simple text link</a></ttc-tripper-launcher>`}
            </CodeBlock>

            <hr className="page-divider" />

            <h2 className="lexend header">Styling the Launcher</h2>
            <div className="inter body-text">
              <strong>Tripper Launcher</strong> makes available css variables
              for easy styling. You can control the Tripper modal using css
              style overrides.
            </div>

            <div className="playground-section mb-3">
              <CodeBlock btnLabel="Copy style overrides">
                {`.style-overrides {
                    --trpr-launcher-modal-max-width: 90vw;
                    --trpr-launcher-modal-width: 90vw;
                    --trpr-launcher-modal-height: calc(100vh - 170px);
                    --trpr-launcher-modal-max-height: calc(100vh - 170px);
                    --trpr-launcher-modal-z-index: 999999;
                  `}
                }
              </CodeBlock>

              <CodeBlock btnLabel="Copy styled embed">
                {`<div class="style-overrides">
                  <ttc-tripper-launcher variant="${guideType}" id="${trprID}" lang="${lang}" />
                </div>`}
              </CodeBlock>
            </div>
          </div>
        </div>

        <div className="STAGE p-3 flex flex-1 right-0 flex-col gap-6 items-center justify-center">
          <div ref={wcRef} className="TRIPPER_WRAPPER">
            <ttc-tripper-launcher
              type="ITINERARY"
              trpr-id={trprID}
              lang={lang}
              variant={variant}
              btn-label={btnLabel}
              ref={wcRef}
              type={guideType}
            />
          </div>
          <CodeBlock btnLabel="Copy launcher code">
            {`<ttc-tripper-launcher type="${guideType}" variant="${variant}" trpr-id="${trprID}" lang="${lang}" />
            `}
          </CodeBlock>
        </div>
      </div>
    </>
  );
}

export default App;
